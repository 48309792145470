var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.jobItems.length > 0 && _vm.$route.name === 'jobs')?_c('v-row',{staticClass:"stats"},[_c('v-col',{attrs:{"cols":"12"}},[_c('JobStats')],1)],1):_vm._e(),(_vm.showAlert)?_c('v-row',[_c('v-col',{attrs:{"cols":"4"}}),_c('v-col',{attrs:{"cols":"4"}},[_c('SuccessAlert',{attrs:{"data-cy":"alert","type":_vm.alertType,"message":_vm.alertMessage}})],1),_c('v-col',{attrs:{"cols":"4"}})],1):_c('v-data-table',{staticClass:"px-6 mt-5",attrs:{"dense":"","search":_vm.search,"loading-text":"loading...","loading":_vm.loading,"no-data-text":"No Jobs available","headers":_vm.headers,"items":_vm.jobItems,"items-per-page":10,"show-select":"","item-key":"_uuid","sort-by":['createdDate'],"sort-desc":[true],"single-select":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.createdDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.createdDate).toLocaleString()))])]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[(item.state === 'ACTIVE')?_c('v-icon',{attrs:{"color":"#427cbe"}},[_vm._v("mdi-run")]):_vm._e(),(item.state === 'COMPLETED')?_c('v-icon',{attrs:{"color":"#40aa53"}},[_vm._v("mdi-flag-checkered")]):_vm._e(),(item.state === 'EXTERNALLY_TERMINATED')?_c('v-icon',{attrs:{"color":"#848484"}},[_vm._v("mdi-close-circle-outline")]):_vm._e(),(item.state === 'FAILED')?_c('v-icon',{attrs:{"color":"#f34a6a"}},[_vm._v("mdi-alert-outline")]):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.state))])])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"data-cy":"show_results","disabled":item.state !== 'COMPLETED',"color":"primary","icon":"","x-small":"","to":{ name: 'results' }},nativeOn:{"click":function($event){return _vm.getResultsDatas(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-chart-areaspline")])],1)]}}],null,true)},[_c('span',[_vm._v("Show Results")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"data-cy":"copy","color":"primary","icon":"","x-small":""},on:{"click":function($event){return _vm.copyToClipboard(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Copy Job Configuration to Clipboard")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"data-cy":"show_info","icon":"","x-small":"","color":"primary"},on:{"click":function($event){return _vm.info(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-clipboard-text-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Show Job Configuration & Info")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":item.structures.length === 0,"data-cy":"load_to_ui","color":"primary","icon":"","x-small":""},on:{"click":function($event){return _vm.loadToUI(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-weather-cloudy-arrow-right")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit Structure")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":item.state !== 'ACTIVE',"data-cy":"cancel_job","color":"primary","icon":"","x-small":""},on:{"click":function($event){return _vm.cancel(item._uuid)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cancel")])],1)]}}],null,true)},[_c('span',[_vm._v("Cancel running job")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"data-cy":"delete","color":"primary","icon":"","x-small":""},on:{"click":function($event){return _vm.askConfirmation(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_vm._v("Description: "+_vm._s(item.description))])]}},{key:"item.data-table-select",fn:function(ref){
var item = ref.item;
return [_c('v-radio-group',{model:{value:(_vm.radios),callback:function ($$v) {_vm.radios=$$v},expression:"radios"}},[_c('v-radio',{attrs:{"value":item},on:{"click":function($event){return _vm.prepareJobInfo(item)}}})],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('ConfigurationInfo',{ref:"info",attrs:{"data-cy":"job_info","job":_vm.job,"structures":_vm.structures,"jobParam":_vm.jobParam,"hasTabs":_vm.hasTabs,"copyData":_vm.copyData}}),_c('ConfirmDialog',{attrs:{"visible":_vm.showConfirmDialog},on:{"close":function($event){_vm.showConfirmDialog = false},"remove":_vm.deleteJob}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }